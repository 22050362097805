@import "../Media.scss";

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 1388px;
  width: 100%;
  margin: 0 auto;
  padding: 38px 32px;

  .header__logo {
    position: relative;
    width: 232px;

    &_startpage {
      &::after {
        display: block;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 15px;
      z-index: 0;
      display: block;
      width: 233.74px;
      height: 52.77px;
      background: #ffffff;
      filter: blur(23.5px);
      transform: rotate(-7.1deg);
    }

    img {
      position: relative;
      z-index: 1;
      width: 100%;
    }
  }

  .header__links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    list-style: none;
    color: #000000;
    font-size: 20px;

    &_startpage {
      color: #ffffff;
    }

    .header__links-link {
      cursor: pointer;

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

@include desktop {
  .header {
    padding: 38px 20px;
  }
}

@include tablets {
  .header {
    padding: 38px 20px;
  }
}

@include phones {
  .header {
    padding-top: 23px;
    padding-bottom: 35px;

    .header__logo {
      width: 119px;

      &::after {
        position: absolute;
        width: 203px;
        height: 43px;
        left: -9px;
        top: -20px;

        background: rgba(255, 255, 255, 0.9);
        filter: blur(44.5px);
      }
    }

    .header__links {
      flex-direction: row-reverse;
      justify-content: flex-start;
      max-width: 150px;
      padding: 0;
      font-size: 13px;

      // .header__links-link {
      //     &:nth-child(2) {
      //         display: none;
      //     }

      //     &:nth-child(3) {
      //         margin-right: 20px;
      //     }
      // }
    }
  }
}

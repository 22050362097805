@import "../Media.scss";

.passenger-form-page {
  margin-top: 120px;
  padding-bottom: 300px;
  background: linear-gradient(180deg, #d1dbff 0%, #ffffff 100%);

  .progress-bar {
    .title {
      &::after {
        width: calc(50% - 40px);
      }
    }
  }

  .passenger-form-page__content {
    max-height: 100%;
    max-width: 1313px;
    margin: 0 auto;
    padding: 63px 10px 0;

    .train__info {
      margin-bottom: 30px;

      .train__departure-arival {
        .train__departure-arival-stations-services {
          .train__departure-arival-services {
            display: none;
          }
        }
      }
    }

    h2 {
      font-size: 30px;
      margin-bottom: 20px;
    }

    .passenger-form-page__content-book-tickets {
      display: flex;
      justify-content: space-between;
      max-width: 370px;
      font-size: 24px;
      font-weight: 500;
      margin: 74px 0 11px;
    }

    .passenger-form-page__content-book-btn {
      color: #ffffff;
      height: 77px;
      width: 370px;
      font-size: 27px;
      background: linear-gradient(
        90.83deg,
        #74eeff 4.61%,
        #5b9dff 47.51%,
        #a64dff 114.58%
      );
      border-radius: 15px;
      border: none;
    }

    .passenger-form-page__content-book-btn-disabled {
      color: #ffffff;
      height: 77px;
      width: 370px;
      font-size: 27px;
      background: linear-gradient(
        90.83deg,
        #888787 4.61%,
        #6b8bd0 47.51%,
        #c7c1c1 114.58%
      );
      border-radius: 15px;
      border: none;
    }
  }
}

@include desktop {
  .passenger-form-page {
    .progress-bar {
      .title {
        &::after {
          width: calc(50% + 10px);
        }
      }
    }
  }
}

@include tablets {
  .passenger-form-page {
    padding-bottom: 150px;

    .progress-bar {
      .title {
        &::after {
          width: calc(50% + 5px);
        }
      }
    }

    .passenger-form-page__content {
      .train__info {
        margin-bottom: 47px;
        padding: 10px 20px;
      }
    }
  }
}

@include phones {
  .passenger-form-page {
    margin-top: 80px;
    padding-bottom: 86px;

    .passenger-form-page__content {
      padding-top: 47px;

      h2 {
        font-size: 20px;
      }

      .passenger-form-page__content-book-tickets {
        max-width: 100%;
        margin-top: 20px;
        font-size: 20px;
      }

      .passenger-form-page__content-book-btn {
        width: 100%;
        height: 59px;
        font-size: 30px;
      }

      .passenger-form-page__content-book-btn-disabled {
        height: 59px;
        width: 100%;
      }
    }
  }
}

.overlayActive {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(1, 1, 1, 0.5); /* Black background with opacity */
  z-index: 5; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.overlayNone {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(1, 1, 1, 0.5); /* Black background with opacity */
  z-index: 5; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

@mixin lg-desktop {
  @media screen and (min-width: 1201px) and (max-width: 1920px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 961px) and (max-width: 1200px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 960px) {
    @content;
  }
}

@mixin min-tablets {
  @media screen and (max-width: 520px) {
    @content;
  }
}

@mixin min-tablets-2 {
  @media screen and (min-width: 520px) and (max-width: 760px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}
@import "../Media.scss";

.refund-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 65vh;
    padding: 110px 10px 540px;
    background: linear-gradient(180deg, #D1DBFF 9.9%, #FFFFFF 120%);

    .refund-page__content {
        display: flex;
        flex-direction: column;
        max-width: 953px;
        margin: 0 auto;

        .refund-page__content-header {
            margin-bottom: 34px;
            font-size: 40px;
            font-weight: 600;
        }

        .refund-page__content-form {
            margin-bottom: 34px;
            padding: 33px 41px 30px;
            background: #FFFFFF;
            border-radius: 16px;

            .refund-page__content-form-header {
                margin-bottom: 19px;
                font-size: 26px;
                font-weight: 500;
            }

            .refund-page__content-form-input {
                max-width: 441px;
                margin-bottom: 19px;

                .form-label {
                    margin-bottom: 3px;
                    font-size: 22px;
                    font-weight: 400;
                }

                .form-control {
                    height: 77px;
                    border-radius: 15px;
                }
            }

            .refund-page__content-form-description {
                margin: 0;
                line-height: 23px;
                font-size: 20px;
                font-weight: 400;
                color: #000000;
            }
        }

        .refund-page__content-submit-btn {
            align-self: center;
            width: 650px;
            height: 77px;
            font-size: 35px;
            font-weight: 500;
            background: linear-gradient(90.83deg, #74EEFF 4.61%, #5B9DFF 47.51%, #A64DFF 114.58%);
            border-radius: 15px;
            border: none;
        }
    }

}

@include tablets {
    .refund-page {
        .refund-page__content {
            .refund-page__content-header {
                font-size: 36px;
            }

            .refund-page__content-form {
                padding: 25px 30px 25px;

                .refund-page__content-form-header {
                    font-size: 22px;
                }

                .refund-page__content-form-input {
                    .form-label {
                        font-size: 20px;
                    }

                    .form-control {
                        height: 60px;
                    }
                }

                .refund-page__content-form-description {
                    font-size: 16px;
                }
            }

            .refund-page__content-submit-btn {
                max-width: 500px;
                font-size: 25px;
            }
        }
    }
}

@include min-tablets {
    .refund-page {
        padding-bottom: 240px;

        .refund-page__content {
            .refund-page__content-header {
                font-size: 30px;
            }

            .refund-page__content-form {
                .refund-page__content-form-header {
                    font-size: 20px;
                }

                .refund-page__content-form-input {
                    .form-label {
                        font-size: 16px;
                    }

                    .form-control {
                        height: 50px;
                        font-size: 16px;
                    }
                }

                .refund-page__content-form-description {
                    font-size: 12px;
                    line-height: normal;
                }
            }

            .refund-page__content-submit-btn {
                width: 80%;
                height: 60px;
                font-size: 22px;
            }
        }
    }
}

@include phones {
    .refund-page {
        padding: 70px 10px 150px;

        .refund-page__content {
            .refund-page__content-header {
                font-size: 25px;
            }

            .refund-page__content-form {
                padding: 20px 15px;

                .refund-page__content-form-header {
                    font-size: 16px;
                }

                .refund-page__content-form-input {
                    .form-label {
                        font-size: 12px;
                    }

                    .form-control {
                        height: 40px;
                        font-size: 12px;
                    }
                }

                .refund-page__content-form-description {
                    font-size: 10px;
                }
            }

            .refund-page__content-submit-btn {
                height: 40px;
                font-size: 16px;
            }
        }
    }
}
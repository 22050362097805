@import "../../Media.scss";

.train__info {
  max-width: 1293px;
  margin: 0 auto;
  padding: 10px 48px 20px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: -1px 4px 12px rgba(56, 94, 131, 0.28);

  .train__main {
    margin: 0;
    color: #000000;
    font-weight: 500;
    font-size: 15px;

    .train__main-number {
      display: flex;
      align-items: center;
      max-width: fit-content;
      margin: 0;
      padding: 0px 15px;
      text-align: center;
      border-radius: 15px;
      background-color: #e9e9e9;
    }

    .train__main-path {
      max-width: fit-content;
    }

    .train__main-electronic-registration {
      max-width: fit-content;
    }

    .train__main-description {
      display: flex;
      align-items: center;
      max-width: fit-content;
      margin-left: 30px;
      color: #707eff;
      background: #e6f3ff;
      border-radius: 16px;
    }
  }

  .train__departure-arival {
    padding: 16px 0 0;
    margin: 0;

    .train__departure-arival-time-info {
      margin: 0;
      padding: 0;

      .train__departure-arival-departure,
      .train__departure-arival-arrival {
        display: flex;
        margin: 0;
        padding: 0;
        max-width: fit-content;

        .train__departure-arival-time {
          display: flex;
          align-items: flex-end;
          max-width: fit-content;
          margin: 0;
          margin-right: 3px;
          padding: 0;
          font-size: 40px;
          font-weight: 700;
          line-height: 45px;
        }

        .train__departure-arival-date {
          display: flex;
          align-items: flex-end;
          max-width: fit-content;
          margin: 0;
          padding: 0;
          font-size: 15px;
          font-weight: 200;
        }
      }

      .train__departure-arival-time-trip-duration {
        display: flex;
        align-items: flex-end;
        margin: 0 40px;
        padding: 0;
        max-width: fit-content;
      }
    }

    .train__departure-arival-stations-services {
      max-width: 85%;
      margin: 0;
      padding: 0;

      .train__departure-arival-stations {
        display: flex;
        justify-content: space-between;
        max-width: 510px;
        margin: 0;
        padding: 0;

        .train__departure-arival-stations-departure,
        .train__departure-arival-stations-arrival {
          max-width: 210px;
          margin: 0;
          padding: 0;
          font-size: 15px;
          font-weight: 500;
        }
      }

      .train__departure-arival-services {
        display: flex;
        max-width: fit-content;
        margin: 0;
        padding: 0;

        .services__img {
          position: relative;
          max-width: 31px;
          margin-right: 10px;

          img {
            padding: 0;

            &:nth-last-child(1) {
              margin-right: 0;
            }
          }

          .services__img-description {
            position: absolute;
            bottom: 25px;
            left: -55px;
            width: 135px;
            background: rgba(231, 243, 255, 0.8);
            border-radius: 5px;
            text-align: center;
            color: #000000;
            font-size: 12px;
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.2s, visibility 0.2s;
          }

          &:hover .services__img-description {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    .train__departure-arival-description {
      margin: 0;
      padding: 0;
      margin-top: 10px;
      font-size: 12px;

      span {
        padding: 0;
      }
    }
  }
}

@include desktop {
  .train__info {
    padding: 10px 20px;

    .train__departure-arival {
      .train__departure-arival-time-info {
        .train__departure-arival-time-trip-duration {
          margin: 0 20px;
        }
      }

      .train__departure-arival-stations-services {
        justify-content: flex-start;
        max-width: 100%;

        .train__departure-arival-stations {
          max-width: 467px;
        }

        .train__departure-arival-services {
          max-width: fit-content;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

@include tablets {
  .train__info {
    position: relative;
    padding: 5px 5px 0 0;

    .train__main {
      .train__main-number {
        padding: 0 10px;
      }

      .train__main-path {
        padding: 0 5px;
      }

      .train__main-description {
        margin-left: 0;
      }
    }

    .train__departure-arival {
      padding-top: 20px;

      .train__departure-arival-time-info {
        margin-bottom: 5px;

        .train__departure-arival-time-trip-duration {
          margin: 0 22px;
        }
      }

      .train__departure-arival-stations-services {
        max-width: 100%;

        .train__departure-arival-stations {
          max-width: 475px;
        }

        .train__departure-arival-services {
          position: absolute;
          bottom: 5px;
          right: 20px;
        }
      }
    }
  }
}

@include min-tablets-2 {
  .train__info {
    position: initial;
    width: 100%;
    padding: 20px 20px;
    border-bottom: 1px solid #d2d2d2;

    .train__departure-arival {
      .train__departure-arival-time-info {
        justify-content: space-between;
        max-width: 85%;

        .train__departure-arival-time-trip-duration {
          margin: 0;
        }
      }

      .train__departure-arival-stations-services {
        .train__departure-arival-stations {
          max-width: calc(85% + 73px);
        }

        .train__departure-arival-services {
          bottom: 5px;
          left: 20px;
        }
      }
    }
  }
}

@include min-tablets {
  .train__info {
    position: initial;
    width: 100%;
    padding: 20px 16px 10px;
    border-bottom: 1px solid #d2d2d2;

    .train__main {
      font-size: 13px;
    }

    .train__departure-arival {
      .train__departure-arival-time-info {
        justify-content: space-between;
        max-width: 85%;
        margin-bottom: 0;

        .train__departure-arival-departure,
        .train__departure-arival-arrival {
          .train__departure-arival-time {
            font-size: 34px;
          }

          .train__departure-arival-date {
            font-size: 14px;
          }
        }

        .train__departure-arival-time-trip-duration {
          margin: 0 14px;
          font-size: 14px;
        }
      }

      .train__departure-arival-stations-services {
        .train__departure-arival-stations {
          max-width: calc(85% + 70px);

          .train__departure-arival-stations-departure,
          .train__departure-arival-stations-arrival {
            max-width: 185px;
            font-size: 12px;
          }
        }

        .train__departure-arival-services {
          bottom: 5px;
          left: 20px;
        }
      }
    }
  }
}

@include phones {
  .train__info {
    position: initial;
    width: 100%;
    padding: 10px 10px;
    border-bottom: 1px solid #d2d2d2;

    .train__main {
      font-size: 10px;

      .train__main-number {
        padding: 0 5px;
      }

      .train__main-path {
        padding: 0 3px;
      }

      .train__main-description {
        padding: 0 5px;
      }
    }

    .train__departure-arival {
      padding-top: 10px;

      .train__departure-arival-time-info {
        justify-content: space-between;
        max-width: 80%;

        .train__departure-arival-departure,
        .train__departure-arival-arrival {
          .train__departure-arival-time {
            font-size: 20px;
            line-height: normal;
          }

          .train__departure-arival-date {
            font-size: 10px;
          }
        }

        .train__departure-arival-time-trip-duration {
          margin: 0 10px;
          font-size: 10px;
        }
      }

      .train__departure-arival-stations-services {
        .train__departure-arival-stations {
          max-width: calc(81% + 55px);

          .train__departure-arival-stations-departure,
          .train__departure-arival-stations-arrival {
            max-width: 135px;
            font-size: 12px;
          }
        }

        .train__departure-arival-services {
          bottom: 5px;

          .services__img {
            max-width: 22px;

            img {
              width: 100%;
            }

            .services__img-description {
              bottom: 25px;
              left: -55px;
              width: 110px;
              font-size: 10px;
            }
          }
        }
      }

      .train__departure-arival-description {
        margin-top: 5px;
        font-size: 10px;
      }
    }
  }
}

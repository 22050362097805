.liveSearchInputContainer {
  position: relative;

  .searchInput {
    width: 100%;
    height: 50.21px;
    padding: 10px;
    text-align: left;
    border: none;
    border-radius: 5px;
    background: linear-gradient(91.1deg, #ffe5be 2.62%, #fff4ea 99.65%);

    &::placeholder {
      font-size: 16px;
      font-weight: 500;
      color: transparent;

      @media screen and (max-width: 480px) {
        color: #000000;
      }
    }

    &:focus {
      outline: none !important;
      border-color: #719ece;
    }

    @media screen and (max-width: 480px) {
      font-size: 16px;
      font-weight: 500;
      height: 56px;
    }
  }

  .searchInputNeedPlaceHolder {
    @extend .searchInput;
    border: 1px solid #d9d9d9;
    border-radius: 15px;
    background: linear-gradient(91.1deg, #ffe5be 2.62%, #fff4ea 99.65%);

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      color: #525050;

      @media screen and (max-width: 480px) {
        color: #000000;
      }
    }
  }
  .responseDropDown {
    background-color: #ffffff;
    box-shadow: 0 0 3px #719ece;
    text-align: left;
    border-radius: 5px;
    position: absolute;
    width: 100%;

    z-index: 100;

    .responseItem {
      padding: 10px;
      cursor: pointer;
      color: #0073ff;

      &:hover {
        color: #f870a6;
        background-color: #fad2d7;
      }
    }
  }

  .open {
    visibility: visible;

    .full {
      height: max-content;
    }
    .overflowed {
      max-height: 40vh;
      overflow: auto;
    }
  }

  .close {
    visibility: hidden;
  }
}

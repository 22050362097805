@import "../../Media.scss";

.guarantees-items {
    max-width: 1355px;
    margin: 0 auto;
    color: #FFFFFF;
    font-size: 22px;

    .items-mobile {
        display: none;
    }

    .items-desktop {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 10px;

        .guarantees-item {
            .item-img {
                max-width: 160px;
                height: 128px;
                margin: 0 auto;
                margin-bottom: 10px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            p {
                max-width: 175px;
                margin: 0 auto;
            }
        }
    }
}

@include tablets {
    .guarantees-items {
        height: 280px;

        .slick-slider {
            max-width: 300px;
            margin: 0 auto;
        }

        .slick-dots {
            bottom: -40px;

            li {
                button {
                    padding: 0;

                    &::before {
                        font-size: 11px;
                        color: #B3B3B3;
                    }
                }
            }
        }

        .items-mobile {
            display: block;

            .guarantees-item {
                .item-img {
                    height: 175px;
                    width: 215px;
                    margin: 0 auto;

                    img {
                        margin: 0 auto;
                    }
                }

                p {
                    text-align: center;
                    font-size: 18px;
                }
            }
        }


        .items-desktop {
            display: none;
        }
    }
}
@import "../Media.scss";

.passenger-info {
  margin: 0;
  margin-top: 35px;
  padding: 0;

  .passenger-info__car-number {
    max-width: 216px;
    margin: 0;
    padding: 3px 0;
    text-align: center;
    border-radius: 15px;
    font-size: 25px;
    background: #ffffff;
  }

  .passenger-info__info-table {
    margin: 0;
    margin-top: 27px;
    font-size: 22px;
    border-radius: 15px;
    background: #ffffff;
    text-align: center;

    .passenger-info__info-table-header {
      padding: 10px 10px;
      color: #666666;
      font-weight: 400;
      border-bottom: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;

      &:nth-last-child(1) {
        border-right: none;
      }
    }

    .passenger-info__info-table-col {
      border-right: 1px solid #d9d9d9;

      h5 {
        display: none;
      }

      p {
        font-weight: 500;
        margin: 0;
        padding: 17px 0;
      }

      &:nth-last-child(1) {
        border-right: none;
      }
    }
  }
}

@include desktop {
  .passenger-info {
    .passenger-info__info-table {
      font-size: 16px;
    }
  }
}

@include tablets {
  .passenger-info {
    .passenger-info__info-table {
      display: flex;
      flex-direction: column;
      text-align: left;

      .passenger-info__info-table-header {
        display: none;
      }

      .passenger-info__info-table-collumns {
        display: flex;
        flex-direction: column;

        .passenger-info__info-table-col {
          display: flex;
          flex-direction: column;
          padding: 0;

          h5 {
            display: block;
            margin: 0;
            padding: 8px 16px;
            color: #666;
            font-size: 20px;
            font-weight: 400;
          }

          p {
            margin: 0;
            padding: 8px 16px;
            font-size: 20px;
            border: none;
          }
        }
      }
    }
  }
}

@include min-tablets {
  .passenger-info {
    .passenger-info__car-number {
      max-width: 127px;
      margin-bottom: 9px;
      padding: 6px 0;
      font-size: 13px;
      border-radius: 5px;
    }

    .passenger-info__info-table {
      border-radius: 5px;

      .passenger-info__info-table-collumns {
        .passenger-info__info-table-col {
          h5,
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
}

.additional-info {
  font-size: 12px;
}

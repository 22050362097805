@import "../../Media.scss";

.start-page {
  background-color: #27212b;

  .start-page__main-screen {
    height: 1000px;
    padding: 0 10px;
    background-image: url("../../../Assets/startPage/background.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .nav-tabs {
    max-width: 1317px;
    margin: 0 auto;
    border-bottom: none;

    .nav-item {
      max-width: 222px;
      margin-top: 235px;

      .start-page__main-screen-nav-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;

        P {
          margin: 0;
        }
      }

      .nav-link {
        padding: 12px 50px 12px 27px;
        color: #000000;
        background: linear-gradient(
          90deg,
          #ffdca8 12.65%,
          rgba(255, 212, 147, 0.06) 107.55%
        );
        border: none;
      }

      &:nth-child(1) {
        .nav-link {
          border-radius: 9px 0 0 0;
        }
      }

      &:nth-child(2) {
        .nav-link {
          border-radius: 0 9px 0 0;
        }
      }

      .active {
        background: #ffffff;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .start-page__main-screen {
      max-height: 735px;
      background-image: url("../../../Assets/startPage/background2.png");
    }
  }
}

.start-page__blog-answers-section {
  height: 1105px;
  background-image: url("../../../Assets/startPage/background3.png");
  background-size: cover;
  background-repeat: no-repeat;

  .start-page__blog-answers-section-blog {
    margin-top: 10px;

    .start-page__blog-answers-section-blog-header {
      display: none;
      position: relative;
      max-width: 521px;
      margin: 0 auto;
      margin-bottom: 20px;
      font-size: 25px;
      font-weight: 300;
      color: #ffffff;
    }
  }

  .start-page__blog-answers-section-answers {
    padding: 0 20px;

    .start-page__blog-answers-section-answers-content {
      max-width: 1355px;
      margin: 0 auto;
      padding: 27px 52px 11px;
      border-radius: 31px;
      background: linear-gradient(
        89.69deg,
        #ffe3b8 0.38%,
        rgba(121, 121, 121, 0.19) 99.87%
      );

      .start-page__blog-answers-section-answers-content-header {
        margin-bottom: 45px;
        font-size: 41px;
        font-weight: 500;
      }

      .accordion {
        .accordion-item {
          margin-bottom: 36px;
          border-radius: 10px;

          .accordion-header {
            button {
              padding: 19px 26px 19px 43px;
              font-size: 28px;
              font-weight: 400;
              border-radius: 10px;

              &:focus {
                color: #000000;
                border: none;
                box-shadow: none;
                background-color: #ffffff;
              }

              &:not(.collapsed) {
                color: #000000;
                border: none;
                box-shadow: none;
                background-color: #ffffff;
              }
            }
          }

          .accordion-body {
            padding: 19px 26px 19px 43px;
          }
        }
      }
    }
  }
}

.start-page__guarantees-section {
  padding: 45px 10px 0;
}

.start-page__subscribe-section {
  padding: 205px 10px 117px;
  max-width: 1355px;
  margin: 0 auto;

  .start-page__subscribe-section-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;

    .start-page__subscribe-section-form-input {
      max-width: 354px;
      background: #ffffff;
      border-radius: 15px;
      color: #707eff;

      &::placeholder {
        color: #707eff;
      }

      &:focus {
        border: none;
        box-shadow: none;
      }
    }

    .start-page__subscribe-section-form-img {
      width: 100px;

      img {
        width: 100%;
      }
    }

    .start-page__subscribe-section-form-description {
      max-width: 720px;
      margin: 0;
      font-size: 26px;
      font-weight: 300;
      line-height: 31px;
      letter-spacing: 1.5px;
      color: #ffffff;
    }
  }
}

@include desktop {
  .start-page {
    .start-page__subscribe-section {
      padding: 150px 10px 117px;

      .start-page__subscribe-section-form {
        .start-page__subscribe-section-form-input {
          max-width: 250px;
        }

        .start-page__subscribe-section-form-description {
          max-width: 550px;
        }
      }
    }
  }
}

@include tablets {
  .start-page {
    .start-page__main-screen {
      .nav-tabs {
        max-width: 480px;
        margin-top: 25px;

        .nav-item {
          margin-top: 160px;
        }
      }
    }

    .start-page__blog-answers-section {
      max-height: 795px;

      .start-page__blog-answers-section-answers {
        .start-page__blog-answers-section-answers-content {
          max-width: 521px;
          border-radius: 0;
          padding: 20px 10px 29px;

          .start-page__blog-answers-section-answers-content-header {
            max-width: 360px;
            font-size: 25px;
          }

          .accordion {
            .accordion-item {
              margin-bottom: 32px;

              .accordion-header {
                button {
                  padding: 11px 13px;
                  font-size: 16px;
                }
              }

              .accordion-body {
                padding: 11px 13px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .start-page__subscribe-section {
      padding: 100px 0 100px;

      .start-page__subscribe-section-form {
        justify-content: space-around;
        max-width: 521px;
        margin: 0 auto;

        .start-page__subscribe-section-form-img {
          display: none;
        }

        .start-page__subscribe-section-form-input {
          width: 160px;
          height: 35px;
          font-size: 14px;
          border-radius: 27px;

          &::placeholder {
            font-size: 10px;
          }
        }

        .start-page__subscribe-section-form-description {
          max-width: 270px;
          font-size: 14px;
          letter-spacing: 0.1px;
          line-height: normal;
        }
      }
    }
  }
}

@include min-tablets {
  .start-page {
    .start-page__main-screen {
      .nav-tabs {
        max-width: 440px;
        margin-top: 0px;

        .nav-item {
          margin-top: 160px;
          max-width: 210px;
        }
      }
    }
  }
}

@include phones {
  .start-page {
    .start-page__main-screen {
      .nav-tabs {
        max-width: 349px;
        margin-bottom: 13px;

        .nav-item {
          margin-top: 90px;

          .start-page__main-screen-nav-title {
            font-size: 14px;

            img {
              max-height: 23px;
            }
          }

          .nav-link {
            width: 149px;
            height: 31px;
            padding: 4px 18px;
            border-radius: 34px;
            background: linear-gradient(91.1deg, #ffe5be 2.62%, #fff4ea 99.65%);
          }

          &:focus-visible {
            border: none;
          }

          &:nth-child(1) {
            .nav-link {
              border-radius: 34px;
            }
          }

          &:nth-child(2) {
            .nav-link {
              border-radius: 34px;
            }
          }

          .active {
            background: #ffffff;
          }
        }
      }
    }

    .start-page__blog-answers-section {
      position: relative;
      bottom: 10px;

      .start-page__blog-answers-section-blog {
        .start-page__blog-answers-section-blog-header {
          max-width: 340px;
          display: block;
        }
      }

      .start-page__blog-answers-section-answers {
        padding: 0;
      }
    }

    .start-page__subscribe-section {
      .start-page__subscribe-section-form {
        .start-page__subscribe-section-form-input {
          width: 132px;
          height: 27px;
          font-size: 10px;
          border-radius: 27px;

          &::placeholder {
            font-size: 10px;
          }
        }

        .start-page__subscribe-section-form-description {
          max-width: 222px;
          font-size: 10px;
          letter-spacing: 0.6px;
          line-height: normal;
        }
      }
    }
  }
}

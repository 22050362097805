@import "../Media.scss";

.passenger-form-page-preform {
  padding: 16px 48px;
  background: #ffffff;
  box-shadow: -1px 4px 12px rgba(56, 94, 131, 0.28);
  border-radius: 15px;

  &__resetAndNext {
    display: flex;
    justify-content: space-between;
    width: 320px;
    margin-top: 20px;

    .button {
      width: 150px;
      height: 30px;
      font-weight: 500;
      color: #000000;
      border-radius: 10px;
      border: none;
      background: linear-gradient(92.61deg, #ffdca8 23.46%, #ffc8c5 112.62%);
      filter: drop-shadow(-1px 4px 12px rgba(56, 94, 131, 0.28));
    }
  }

  &__luxury-resetAndNext {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .button {
      width: 170px;
      height: 40px;
      font-weight: 500;
      color: #000000;
      border-radius: 10px;
      border: none;
      background: linear-gradient(92.61deg, #ffdca8 23.46%, #ffc8c5 112.62%);
      filter: drop-shadow(-1px 4px 12px rgba(56, 94, 131, 0.28));
    }

    .button__next {
      margin-left: 60px;
    }
  }

  &__add-passengers {
    .add-passengers__added {
      margin-top: 20px;
      font-size: 20px;
    }
    .add-passengers__form {
      display: flex;
      margin-top: 20px;

      .select-category {
        width: 130px;
        padding: 5px 10px 5px 5px;
        border: 1px solid #ced4da;
        border-radius: 10px;
        &:focus {
          outline: none;
        }
      }

      .select-seats {
        padding: 5px 5px;
        border: 1px solid #ced4da;
        border-radius: 10px;
        &:focus {
          outline: none;
        }
      }

      .add-passengers__buttons {
        .button {
          height: 35px;
          padding: 0 15px;
          font-weight: 500;
          color: #000000;
          border-radius: 10px;
          border: none;
          background: linear-gradient(
            92.61deg,
            #ffdca8 23.46%,
            #ffc8c5 112.62%
          );
          filter: drop-shadow(-1px 4px 12px rgba(56, 94, 131, 0.28));
        }
        .buttons__add-pessanger {
          margin-top: 0;
          margin-left: 10px;
        }

        .buttons__add-baby {
          margin-top: 0;
        }
      }
    }
  }
}

.passenger-form-page-preform__error {
  width: fit-content;
  margin-top: 40px;
  padding: 10px 10px;
  font-size: 20px;
  color: #ff3838;
  border: 1px solid #ffb8b8;
  border-radius: 8px;
  background-color: rgba(255, 56, 56, 0.05);
}

.passenger-form-page-preform__limitation {
  max-width: 800px;
  margin-top: 20px;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 8px;
  background-color: rgba(255, 186, 0, 0.05);
  color: #555555;

  h3 {
    font-size: 20px;
    color: #ffba00;
  }
}

@include min-tablets-2 {
  .passenger-form-page-preform {
    padding: 16px 24px;

    h3 {
      font-size: 18px;
    }

    &__add-passengers {
      .add-passengers__added {
        font-size: 16px;
      }

      .add-passengers__form {
        font-size: 14px;

        .add-passengers__buttons {
          .button {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@include min-tablets {
  .passenger-form-page-preform {
    padding: 16px 24px;

    &__resetAndNext {
      width: 100%;

      .button {
        height: 30px;
        font-size: 14px;
      }
    }

    &__luxury-resetAndNext {
      justify-content: space-between;
      width: 100%;

      .button {
        width: 150px;
        height: 35px;
        font-size: 14px;
      }

      .button__next {
        margin: 0;
      }
    }

    &__add-passengers {
      .add-passengers__added {
        font-size: 14px;
      }

      .add-passengers__form {
        font-size: 12px;

        .add-passengers__buttons {
          .button {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@include phones {
  .passenger-form-page-preform {
    padding: 16px 24px;

    h3 {
      font-size: 16px;
    }

    &__resetAndNext {
      .button {
        width: 45%;
        height: 25px;
        font-size: 12px;
      }
    }

    &__luxury-resetAndNext {
      .button {
        width: 120px;
        height: 30px;
        font-size: 12px;
      }
    }

    &__add-passengers {
      .add-passengers__added {
        font-size: 12px;
      }

      .add-passengers__form {
        flex-direction: column;
        font-size: 10px;

        .add-passengers__buttons {
          margin-top: 10px;

          .button {
            height: 30px;
            font-size: 10px;
          }
        }
      }
    }
  }
  .passenger-form-page-preform__error {
    font-size: 16px;
  }

  .passenger-form-page-preform__limitation {
    font-size: 14px;

    h3 {
      font-size: 16px;
    }
  }
}

@import "../Media.scss";

.reservation-submit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 60vh;
  margin-top: 120px;
  background: linear-gradient(rgb(209, 219, 255) 0%, rgb(255, 255, 255) 150%);

  h1 {
    margin-top: 40px;
    margin-bottom: 21px;
  }

  h2 {
    white-space: pre-wrap;
    margin: 0;
    margin-top: 35px;
    padding: 0;
  }

  .reservation-submit__timer {
    padding: 10px 0;
    padding-right: 43px;
    background: #f9ebda;

    .reservation-submit__timer-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 1313px;
      margin: 0 auto;

      h3 {
        margin: 0;
        font-size: 24px;
        font-weight: 400;
      }
    }
  }

  .progress-bar {
    .title {
      &::after {
        width: calc(50% + 200px);
      }
    }
  }

  .reservation-submit__content {
    width: 100%;
    max-width: 1313px;
    margin: 0 auto;
    padding: 0 10px;

    .reservation-submit__additional-info {
      max-width: 1313px;
      font-size: 22px;
    }

    .reservation-submit__content-departure-train-info {
      .train__info {
        margin-top: 35px;

        .train__departure-arival {
          .train__departure-arival-stations-services {
            .train__departure-arival-services {
              display: none;
            }
          }
        }
      }
    }

    .reservation-submit__content-departure-info {
      padding-bottom: 200px;

      .reservation-submit__content-table {
        margin: 0;
        padding: 0;
      }

      .reservation-submit__content-departure-info-contacts {
        margin: 0;
        margin-top: 30px;
        font-size: 22px;
        border-radius: 15px;
        background: #ffffff;

        .col {
          padding: 0;

          h5 {
            color: #666666;
            font-weight: 400;
            border-bottom: 1px solid #d9d9d9;
          }

          p {
            font-weight: 500;
          }

          h5,
          p {
            margin: 0;
            padding: 20px 38px;
          }

          &:nth-child(1) {
            border-right: 1px solid #d9d9d9;
          }
        }
      }

      .form-check {
        margin-top: 49px;
        font-size: 22px;
      }

      .reservation-submit__content-payment-block {
        display: flex;
        flex-direction: column;
        margin-top: 49px;

        .reservation-submit__content-payment-block-price {
          display: flex;
          justify-content: space-between;
          max-width: 370px;
          font-size: 22px;
          font-weight: 700;

          p {
            margin-bottom: 10px;
          }
        }

        .reservation-submit__content-payment-block-btn {
          display: flex;
          justify-content: space-between;

          .reservation-submit__content-cancel-btn {
            width: 370px;
            height: 77px;
            font-size: 24px;
            border: none;
            border-radius: 15px;
            background: linear-gradient(326deg, #bd4f6c 0%, #d7816a 74%);
          }

          .reservation-submit__content-pay-btn {
            width: 370px;
            height: 77px;
            font-size: 24px;
            border: none;
            border-radius: 15px;
            background: linear-gradient(
              90.83deg,
              #74eeff 4.61%,
              #5b9dff 47.51%,
              #a64dff 114.58%
            );

            &_disabled {
              background: linear-gradient(
                90.83deg,
                #888787 4.61%,
                #6b8bd0 47.51%,
                #c7c1c1 114.58%
              );
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

@include desktop {
  .reservation-submit {
    .progress-bar {
      .title {
        &::after {
          width: 80%;
        }
      }
    }
  }
}

@include tablets {
  .reservation-submit {
    h1,
    h2 {
      margin: 20px 0 9px;
    }

    .progress-bar {
      .title {
        &::after {
          width: 80%;
        }
      }
    }

    .reservation-submit__content {
      .reservation-submit__content-departure-train-info {
        .train__info {
          padding: 10px 20px;
        }
      }

      .reservation-submit__content-departure-info {
        .reservation-submit__content-departure-info-contacts {
          flex-direction: column;
          text-align: left;
          margin-bottom: 20px;

          .col {
            border-bottom: 1px solid #d9d9d9;

            h5,
            p {
              margin: 0;
              padding: 8px 16px;
              font-size: 20px;
              border: none;
            }

            &:nth-child(1) {
              border-right: none;
            }

            &:nth-last-child(1) {
              border: none;
            }
          }
        }

        .reservation-submit__content-payment-block {
          .reservation-submit__content-payment-block-price {
            max-width: 45%;
          }

          .reservation-submit__content-payment-block-btn {
            .reservation-submit__content-pay-btn,
            .reservation-submit__content-cancel-btn {
              width: 45%;
            }
          }
        }
      }
    }
  }
}

@include min-tablets-2 {
  .reservation-submit {
    .reservation-submit__content {
      .reservation-submit__content-departure-info {
        .reservation-submit__content-payment-block {
          .reservation-submit__content-payment-block-btn {
            .reservation-submit__content-cancel-btn {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@include min-tablets {
  .reservation-submit {
    .reservation-submit__timer {
      padding-right: 12px;

      .reservation-submit__timer-content {
        h3 {
          font-size: 13px;
        }
      }
    }

    .reservation-submit__content {
      .reservation-submit__content-departure-info {
        .reservation-submit__content-departure-info-contacts {
          border-radius: 5px;

          .col {
            h5,
            p {
              font-size: 13px;
            }
          }
        }

        .reservation-submit__content-payment-block {
          .reservation-submit__content-payment-block-price {
            max-width: 45%;
            font-size: 14px;
          }

          .reservation-submit__content-payment-block-btn {
            .reservation-submit__content-pay-btn,
            .reservation-submit__content-cancel-btn {
              width: 45%;
              height: 60px;
            }

            .reservation-submit__content-cancel-btn {
              font-size: 14px;
            }
          }
        }
      }
    }

    .form-check-label {
      font-size: 13px;
    }
  }
}

@include phones {
  .reservation-submit {
    margin-top: 80px;

    .reservation-submit__content {
      .reservation-submit__content-departure-info {
        .reservation-submit__content-payment-block {
          .reservation-submit__content-payment-block-price {
            font-size: 14px;
          }

          .reservation-submit__content-payment-block-btn {
            .reservation-submit__content-pay-btn {
              width: 45%;
              height: 50px;
              font-size: 14px;
            }

            .reservation-submit__content-cancel-btn {
              width: 48%;
              height: 50px;
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}

.reservation-page__limitation {
  max-width: 1000px;
  margin-top: 20px;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 8px;
  background-color: rgba(255, 186, 0, 0.05);
  color: #555555;

  h3 {
    font-size: 20px;
    color: #ffba00;
  }
}

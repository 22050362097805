@import "../Media.scss";

.footer {
  background-color: #ffffff;

  .socials {
    max-width: 1303px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 24px 20px 0;

    .email {
      margin: 0;
      align-self: center;
      display: none;
    }

    h2 {
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: 500;
      text-align: end;
    }

    .socials-links {
      display: flex;
      justify-content: flex-end;
      gap: 58px;
      list-style: none;

      .link {
        display: flex;
        align-items: center;

        a {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 400;
          color: #000000;
          text-decoration: none;

          .img {
            margin-right: 2px;
          }

          img {
            width: 100%;
          }

          p {
            margin: 0;
          }
        }
      }
    }
  }

  .links {
    padding: 36px 0 51px;
    border-top: 1px solid #b8bfff;

    .links-container {
      display: flex;
      justify-content: space-between;
      max-width: 1303px;
      margin: 0 auto;
      padding: 0 20px;
    }

    .logo {
      max-width: 108px;

      img {
        width: 100%;
      }
    }

    .link-items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 14px;
      margin-bottom: 0;
      padding: 0 10px;
      list-style: none;

      .item {
        cursor: pointer;

        ul {
          list-style: square;

          li {
            text-align: start;
          }
        }

        table {
          max-width: 550px;
          margin: 0 auto;
          text-align: start;
          border: 1px solid #000000;

          th {
            border: 1px solid #000000;
          }

          td {
            border: 1px solid #000000;
          }
        }

        p {
          margin: 0;
          text-align: start;
        }

        &:nth-child(4) {
          display: none;
        }
      }
    }

    .support {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .im-info {
      max-width: 250px;

      a {
        display: flex;
        align-items: center;
        gap: 5px;
        text-decoration: none;
        color: #000000;
        font-size: 16px;

        img {
          width: 30%;
        }
      }
    }
  }
}

@include desktop {
  .footer {
    .links {
      padding: 36px 0 51px;
    }
  }
}

@include tablets {
  .footer {
    .socials {
      padding: 34px 17px 0;
      justify-content: space-between;

      .email {
        font-size: 14px;
        display: block;
      }

      h2 {
        font-size: 14px;
        text-align: start;
      }

      .socials-links {
        justify-content: flex-start;
        gap: 20px;
        padding: 0;
        margin: 0;

        .link {
          a {
            p {
              display: none;
            }

            .img {
              max-width: 32px;
            }
          }
        }
      }
    }

    .links {
      margin: 0 auto;
      padding: 25px 0 27px 17px;
      border: none;

      .links-container {
        padding: 0;
        flex-direction: column;

        .logo {
          max-width: 110px;
        }

        .link-items {
          padding: 0;
          padding-top: 15px;
          row-gap: 12px;

          .item {
            &:nth-child(4) {
              display: block;
            }

            h2 {
              font-size: 20px;
            }

            h4 {
              font-size: 16px;
            }

            ul {
              font-size: 14px;
            }

            table {
              font-size: 14px;
            }

            p {
              margin-bottom: 5px;
              font-size: 14px;
            }
          }
        }

        .email {
          display: none;
        }

        .im-info {
          margin-top: 15px;

          a {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@include phones {
  .footer {
    .socials {
      .email {
        font-size: 10px;
      }

      h2 {
        font-size: 10px;
        margin-bottom: 10px;
      }

      .socials-links {
        .link {
          a {
            .img {
              max-width: 24px;
            }
          }
        }
      }
    }

    .links {
      padding-top: 25px;

      .links-container {
        .logo {
          max-width: 59px;
        }

        .link-items {
          padding: 0;
          padding-top: 15px;
          row-gap: 0px;

          .item {
            &:nth-child(4) {
              display: block;
            }

            h2 {
              font-size: 14px;
            }

            h4 {
              font-size: 14px;
            }

            ul {
              font-size: 10px;
            }

            table {
              font-size: 10px;
            }

            p {
              font-size: 10px;
            }
          }
        }

        .email {
          display: none;
        }

        .im-info {
          max-width: 200px;
          margin-top: 15px;

          a {
            font-size: 10px;

            img {
              max-width: 25%;
            }
          }
        }
      }
    }
  }
}

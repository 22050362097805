@import "../../Media.scss";

.train {
  display: flex;
  max-width: 1283px;
  min-height: 180px;
  margin: 0 auto;
  margin-top: 24px;
  padding: 8px 48px 8px 0;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: -1px 4px 12px rgba(56, 94, 131, 0.28);

  .train__info {
    box-shadow: none;
  }

  .train__car-type {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 230px;
    padding: 0;

    .train__car-type-items {
      margin: 0;

      .train__car-type-items-item {
        max-width: 100%;
        margin: 0;
        padding: 0;
      }
    }

    .train__car-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 29px;
      padding: 0;
      padding-left: 5px;
      margin-bottom: 2px;
      color: #000000;
      background: #e7f3ff;
      border-radius: 10px;
      border: none;

      .train__car-type-price {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 51%;
        height: 100%;
        padding: 0 3px;
        font-size: 16px;
        font-weight: 600;
        background: #a4d2ff;
        border-radius: 10px;
      }

      &:hover {
        background: #a4d2ff;
        color: #000000;
      }
    }

    .train__car-btn-prime {
      background: #ffd8aa;

      .train__car-type-price {
        background: #ffc076;
      }

      &:hover {
        background: #ffc076;
      }
    }

    .train-submit {
      //background: linear-gradient(90.83deg, #74EEFF 4.61%, #5B9DFF 47.51%, #A64DFF 114.58%);
      border: none;
      border-radius: 10px;
      width: 100%;
      max-width: 230px;
      text-align: center;
      color: #000;
    }
  }
}

@include desktop {
  .train {
    padding: 8px 20px;
  }
}

@include tablets {
  .train {
    padding: 8px 10px;

    .train-info {
      padding: 10px 20px;
      border-radius: 0;
    }
  }
}

@include min-tablets-2 {
  .train {
    position: relative;
    max-height: none;
    height: auto;
    padding: 4px 24px;
    padding: 0;
    flex-direction: column;
    border-radius: 5px;

    .train__car-type {
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;

      .train__car-type-items {
        width: 100%;
        margin-bottom: 32px;
        padding: 0 15px;
        justify-content: space-between;

        .train__car-type-items-item {
          max-width: 45%;
          margin-bottom: 4px;
        }
      }

      .train__car-btn {
        height: 19px;
        font-size: 14px;

        .train__car-type-price {
          font-size: 14px;
        }
      }

      .train-submit {
        max-width: 100%;
        max-height: 32px;
        padding: 4px;
        border-radius: 0 0 5px 5px;
      }
    }
  }
}

@include min-tablets {
  .train {
    position: relative;
    max-height: none;
    height: auto;
    padding: 4px 24px;
    padding: 0;
    flex-direction: column;
    border-radius: 5px;

    .train__car-type {
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;

      .train__car-type-items {
        width: 100%;
        margin-bottom: 30px;
        padding: 0 16px;
        justify-content: space-between;

        .train__car-type-items-item {
          max-width: 48%;
          margin-bottom: 4px;
        }
      }

      .train__car-btn {
        height: 19px;
        font-size: 12px;

        .train__car-type-price {
          font-size: 12px;
        }
      }

      .train-submit {
        max-width: 100%;
        max-height: 32px;
        padding: 4px;
        border-radius: 0 0 5px 5px;
      }
    }
  }
}

@include phones {
  .train {
    max-height: none;
    height: auto;

    .train-info {
      padding: 10px 5px;
    }

    .train__car-type {
      .train__car-type-items {
        padding: 0 5px;

        .train__car-type-items-item {
          max-width: 49%;
        }

        .train__car-btn {
          font-size: 10px;

          .train__car-type-price {
            padding: 0 3px;
            font-size: 10px;
          }
        }
      }
    }
  }
}

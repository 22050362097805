@import "../Media.scss";

.cars-page {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 60vh;
  margin-top: 120px;
  background: linear-gradient(180deg, #d1dbff 0%, #ffffff 120%);

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  h1 {
    max-width: 1293px;
    margin: 0 auto;
    margin-top: 74px;
  }

  .progress-bar {
    .title {
      &::after {
        width: calc(50% - 300px);
      }
    }
  }

  .cars-page__selected-cars {
    padding: 63px 9px 381px;

    .train__info {
      position: relative;
      // .train__departure-arival {
      //   .train__departure-arival-stations-services {
      //     .train__departure-arival-services {
      //       display: none;
      //     }
      //   }
      // }
    }

    .cars-page__selected-cars-carriage {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      max-width: 1293px;
      margin: 0 auto;
      margin-top: 35px;
      padding: 37px;
      background: #ffffff;
      box-shadow: -1px 4px 12px rgba(56, 94, 131, 0.28);
      border-radius: 15px;

      .cars-page__selected-cars-carriage-info {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
        max-width: 95%;
        margin: 0;
        margin-bottom: 30px;
        padding: 0;
      }

      .cars-page__selected-cars-carriage-info-number {
        font-size: 24px;
        font-weight: 500;
      }

      .electronic-registration {
        position: relative;

        .electronic-registration__description {
          position: absolute;
          bottom: 30px;
          left: -55px;
          width: 135px;
          background: rgba(231, 243, 255, 0.8);
          border-radius: 5px;
          text-align: center;
          color: #000000;
          font-size: 12px;
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.2s, visibility 0.2s;
        }

        &:hover .electronic-registration__description {
          visibility: visible;
          opacity: 1;
        }
      }

      .cars-page__selected-cars-carriage-scheme {
        margin-bottom: 30px;
        padding: 0;

        .scheme-block {
          padding: 0;
        }
      }

      .cars-page__selected-cars-carriage-service {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0;

        .carriage-services {
          display: flex;

          .services__img {
            position: relative;
            max-width: 31px;
            margin-right: 10px;

            img {
              padding: 0;

              &:nth-last-child(1) {
                margin-right: 0;
              }
            }

            .services__img-description {
              position: absolute;
              bottom: 30px;
              left: -55px;
              width: 135px;
              background: rgba(231, 243, 255, 0.8);
              border-radius: 5px;
              text-align: center;
              color: #000000;
              font-size: 12px;
              visibility: hidden;
              opacity: 0;
              transition: opacity 0.2s, visibility 0.2s;
            }

            &:hover .services__img-description {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }

      .cars-page__selected-cars-carriage-total-cost {
        display: flex;
        flex-direction: column;

        .service-fee {
          font-size: 14px;
        }
      }

      span {
        font-size: 20px;
      }
    }

    .cars-page__selected-cars-carriage-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 20px;
      max-width: 1293px;
      margin: 0 auto;
      margin-top: 61px;

      .cars-page__selected-cars-carriage-list-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          -1px 4px 12px rgba(56, 94, 131, 0.28);
        border-radius: 15px;

        &:hover {
          cursor: pointer;
          background: #ffd8aa;
        }
      }

      .cars-page__selected-cars-carriage-list-item-picked {
        background: #ffd8aa;
      }

      a {
        width: 100%;
        padding: 0;
      }
    }

    .cars-page__selected-cars-submit {
      justify-content: flex-end;
      max-width: 1293px;
      margin: 0 auto;
      margin-top: 63px;

      .cars-page__selected-cars-submit-btn {
        width: 370px;
        height: 63px;
        font-size: 27px;
        font-weight: 500;
        color: #000000;
        border-radius: 15px;
        border: none;
        background: linear-gradient(92.61deg, #ffdca8 23.46%, #ffc8c5 112.62%);
        filter: drop-shadow(-1px 4px 12px rgba(56, 94, 131, 0.28));

        &:disabled {
          color: #000000;
          border: none;
        }

        &:hover {
          color: #000000;
        }
      }
    }
  }
}

@include desktop {
  .cars-page {
    .progress-bar {
      .title {
        &::after {
          width: calc(50% - 145px);
        }
      }
    }

    .cars-page__selected-cars {
      .cars-page__selected-cars-carriage {
        padding: 30px 20px;
      }
    }
  }
}

@include tablets {
  .cars-page {
    .progress-bar {
      .title {
        &::after {
          width: calc(50% - 95px);
        }
      }
    }

    h1 {
      margin-top: 54px;
    }

    .cars-page__selected-cars {
      padding-bottom: 150px;

      .train__info {
        padding: 10px 20px;

        .train__departure-arival {
          .train__departure-arival-stations-services {
            .train__departure-arival-services {
              right: 20px;
              left: auto;
            }
          }
        }
      }

      .cars-page__selected-cars-carriage {
        padding: 10px 10px;

        .cars-page__selected-cars-carriage-total-cost {
          .service-fee {
            font-size: 12px;
          }
        }

        span {
          font-size: 14px;
        }

        p {
          font-size: 10px;
        }

        .cars-page__selected-cars-carriage-info,
        .cars-page__selected-cars-carriage-scheme {
          margin-bottom: 15px;
        }

        .cars-page__selected-cars-carriage-service {
          .carriage-services {
            .services__img {
              .services__img-description {
                bottom: 45px;
              }
            }
          }
        }
      }

      .cars-page__selected-cars-carriage-list {
        grid-template-columns: repeat(4, 1fr);
        margin-top: 40px;

        .cars-page__selected-cars-carriage-list-item {
          height: 60px;
          font-size: 16px;
        }
      }

      .cars-page__selected-cars-submit-btn {
        width: 50%;
      }
    }
  }
}

@include min-tablets {
  .cars-page {
    .cars-page__selected-cars {
      .cars-page__selected-cars-carriage {
        .cars-page__selected-cars-carriage-info {
          justify-content: flex-start;
          column-gap: 2%;
          max-width: 100%;
        }

        .scheme-container {
          overflow: scroll;

          .scheme {
            width: 900px;
          }
        }

        .cars-page__selected-cars-carriage-service {
          .carriage-services {
            .services__img {
              .services__img-description {
                bottom: 45px;
              }
            }
          }
        }
      }
    }
  }
}

@include min-tablets-2 {
  .cars-page {
    .cars-page__selected-cars {
      .cars-page__selected-cars-carriage {
        .cars-page__selected-cars-carriage-info {
          justify-content: flex-start;
          column-gap: 5%;
          max-width: 100%;
        }

        .scheme-container {
          overflow: scroll;

          .scheme {
            width: 900px;
          }
        }

        .cars-page__selected-cars-carriage-service {
          .carriage-services {
            .services__img {
              .services__img-description {
                bottom: 45px;
              }
            }
          }
        }
      }
    }
  }
}

@include phones {
  .cars-page {
    margin-top: 80px;

    .progress-bar {
      .title {
        &::after {
          width: calc(50% - 70px);
        }
      }
    }

    h1 {
      margin-top: 34px;
      font-size: 20px;
    }

    .cars-page__selected-cars {
      padding-top: 40px;
      padding-bottom: 100px;

      .train__info {
        padding: 10px 5px;
      }

      .cars-page__selected-cars-carriage {
        margin-top: 20px;
        padding: 15px 5px;

        span {
          font-size: 10px;
        }

        p {
          font-size: 8px;
        }

        .cars-page__selected-cars-carriage-info {
          align-items: baseline;
        }

        .cars-page__selected-cars-carriage-info-number {
          font-size: 14px;
        }

        .cars-page__selected-cars-carriage-service {
          .carriage-services {
            .services__img {
              .services__img-description {
                bottom: 30px;
                font-size: 10px;
              }
            }
          }
        }

        .cars-page__selected-cars-carriage-total-cost {
          .service-fee {
            font-size: 8px;
          }
        }
      }

      .cars-page__selected-cars-carriage-list {
        margin-top: 20px;
        .cars-page__selected-cars-carriage-list-item {
          height: 40px;
          font-size: 10px;
        }
      }

      .cars-page__selected-cars-submit {
        margin-top: 20px;

        .cars-page__selected-cars-submit-btn {
          width: 100%;
          max-height: 49px;
          padding: 0;
        }
      }
    }
  }
}

@import '../Media.scss';

.ticket-form {
  max-width: 1317px;
  margin: 0 auto;

  .ticket-form__container {
    max-width: 1317px;
    padding: 16px 20px 32px;
    margin: 0 auto;
  }

  .ticket-form__default-cities {
    font-size: 10px;
    cursor: pointer;

    &:hover {
      color: #888787;
    }
  }

  .ticket-form__mobile-title {
    display: none;
    margin-bottom: 7px;
    color: #ffffff;

    h2 {
      font-size: 20px;
    }
  }

  .ticket-form__elements {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 0 10px 10px 10px;

    label {
      font-size: 20px;
    }

    .ticket-form__elements-city-selection {
      display: flex;
      justify-content: space-between;
      width: 36%;
      padding: 0;

      .ticket-form__elements-city-selection-from,
      .ticket-form__elements-city-selection-to {
        width: 45%;
        padding: 0;
      }

      .ticket-form__elements-city-selection-swap-city-btn {
        display: flex;
        align-items: center;
        margin: 0 4px;

        img {
          cursor: pointer;
        }
      }
    }

    .ticket-form__elements-date-selection {
      display: flex;
      justify-content: space-between;
      width: 36%;
      padding: 0;

      .ticket-form__elements-date-selection-departure-date,
      .ticket-form__elements-date-selection-date-return {
        width: 45%;
        padding: 0;
      }

      .ticket-form__elements-date-selection-departure-date-disabled {
        .railwayDate,
        span {
          opacity: 0.5;
          cursor: auto;
        }
      }

      .ticket-form__elements-date-selection-date-return-disabled {
        .railwayDate,
        span {
          opacity: 0.5;
          cursor: auto;
        }
      }

      .ticket-form__elements-date-selection-date-return-label {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        div {
          margin-top: 0;
          z-index: 10;
        }

        label {
          margin-bottom: 0;
          margin-left: 10px;
        }
      }

      .ticket-form__elements-date-selection-date-img {
        display: flex;
        max-width: 27px;
        margin: 0 4px;
        padding: 0;

        img {
          width: 100%;
        }
      }
    }

    .ticket-form__elements-submit-btn {
      width: 24%;
      padding: 0;

      .button {
        width: 100%;
        height: 80px;
        margin-top: 1rem;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 48px;
        color: #ffffff;
        background: linear-gradient(93.93deg, #5e6eff 14.69%, #c78bb9 53.28%, #ff7e76 89.05%);
        border: none;
        border-radius: 15px;

        &:disabled {
          color: #e7e7e7;
          background: linear-gradient(93.93deg, #a9b2ff 14.69%, #ffb4af 91.87%);
        }
      }
    }
  }
}

.ticket-form__elements-payment {
  position: absolute;
  top: 320px;
  right: 60px;
  display: flex;
  align-items: flex-end;
  color: #ffffff;

  .ticket-form__elements-payment-description {
    margin: 0;
    margin-right: 35px;
  }
}

.ticket-form__scrolldown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 80px;
  margin: 0 auto;
  margin-top: 240px;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
}

@include desktop {
  .ticket-form {
    .ticket-form__container {
      padding: 16px 20px 32px;
    }

    .ticket-form__elements-payment {
      top: 275px;
    }
  }
}

@include tablets {
  .ticket-form {
    .ticket-form__container {
      max-width: 480px;
    }

    .ticket-form__elements {
      .ticket-form__elements-city-selection {
        width: 100%;

        .ticket-form__elements-city-selection-swap-city-btn {
          margin: 0 8px;
        }
      }

      .ticket-form__elements-date-selection {
        width: 100%;
        margin: 15px 0;

        .ticket-form__elements-date-selection-date-img {
          margin: 0 8px;
        }
      }

      .ticket-form__elements-submit-btn {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }

    .ticket-form__elements-payment {
      position: relative;
      top: 0;
      right: 0;
      justify-content: flex-end;
      width: 100%;
      color: #000000;
    }

    .ticket-form__scrolldown {
      margin-top: 100px;
    }
  }
}

@include min-tablets {
  .ticket-form {
    .ticket-form__container {
      max-width: 440px;
    }

    .ticket-form__elements {
      .ticket-form__elements-submit-btn {
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
  }
}

@include phones {
  .ticket-form {
    .ticket-form__container {
      max-width: 349px;
      padding: 21px 16px 15px;
    }

    .ticket-form__default-cities {
      &:nth-child(3) {
        margin-right: 10px;
        margin-left: 10px;
      }

      &:nth-child(4) {
        margin-right: 10px;
      }
    }

    .ticket-form__mobile-title {
      display: block;
      padding: 0;
    }

    .ticket-form__elements {
      justify-content: flex-end;
      border-radius: 10px;

      label {
        display: none;
      }

      .ticket-form__elements-city-selection {
        position: relative;
        flex-direction: column;
        width: 100%;
        max-width: 100%;

        .ticket-form__elements-city-selection-from,
        .ticket-form__elements-city-selection-to {
          width: 100%;
          margin-bottom: 10px;

          input {
            margin-bottom: -7px;
          }
        }

        .ticket-form__elements-city-selection-swap-city-btn {
          position: absolute;
          top: 60px;
          right: 25px;
          width: 20px;
          margin: 0;
          transform: rotate(90deg);

          img {
            width: 100%;
          }
        }
      }

      .ticket-form__elements-date-selection {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;

        .ticket-form__elements-date-selection-departure-date,
        .ticket-form__elements-date-selection-date-return {
          width: 100%;
          margin-bottom: 10px;

          input {
            margin-bottom: -7px;
          }
        }

        .ticket-form__elements-date-selection-date-return {
          position: relative;
          margin-bottom: 30px;
        }

        .ticket-form__elements-date-selection-date-return-label {
          position: absolute;
          top: 15px;
          right: 25px;
        }

        .ticket-form__elements-date-selection-date-img {
          display: none;
        }
      }

      .ticket-form__elements-submit-btn {
        width: 100%;
        margin-bottom: 0;

        .button {
          height: 51px;
          margin-top: 0;
        }
      }
    }

    .ticket-form__elements-payment {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .ticket-form__elements-payment-description {
        margin-right: 10px;
        margin-bottom: 0;
        font-size: 10px;
      }

      .ticket-form__elements-payment-img {
        width: 74px;

        img {
          width: 100%;
        }
      }
    }

    .ticket-form__scrolldown {
      width: 60px;
      height: 60px;
      margin-top: 20px;
    }
  }
}

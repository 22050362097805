@import "../Media.scss";

.progress-bar {
    position: relative;

    ul {
        display: flex;
        max-width: 1293px;
        margin: 0 auto;
        padding: 63px 0 22px;
        font-size: 24px;
        color: #666666;
        list-style: none;

        li {
            margin-right: 118px;

            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }

    .title {
        padding: 0 9px;
        border-bottom: 3px solid #FFFFFF;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            background-color: #6184FF;
        }
    }
}

@include desktop {
    .progress-bar {
        ul {
            justify-content: space-between;
            max-width: 100%;
            padding-top: 40px;
            padding-left: 0;

            li {
                margin-right: 0;
            }
        }
    }
}

@include tablets {
    .progress-bar {
        ul {
            justify-content: space-between;
            font-size: 16px;
            padding-left: 0;

            li {
                margin-right: 0;
            }
        }
    }
}

@include phones {
    .progress-bar {
        ul {
            justify-content: space-between;
            padding: 20px 0 14px;
            font-size: 10px;

            li {
                margin-right: 0;
            }
        }
    }
}
@import "../Media.scss";

.passenger-form-page-preform__luxury-component {
  display: flex;
  flex-direction: column;
  align-items: center;

  .luxury-component__seats {
    margin: 0;
    font-size: 18px;
  }

  .luxury-component__button {
    width: 400px;
    margin-top: 30px;
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    background: linear-gradient(92.61deg, #ffdca8 23.46%, #ffc8c5 112.62%);
  }

  .luxury-component__passenger-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    margin-top: 15px;
    padding: 10px 5px;
    border-radius: 10px;
    background-color: #eaf2f8;

    p {
      margin: 0;
    }

    .luxury-component__number-selected {
      height: 25px;
      width: 25px;
      background-color: #bbb;
      border-radius: 50%;
      text-align: center;
    }

    .luxury-component__button-small {
      padding: 4px 8px;
      font-size: 12px;
      background-color: #de3163;
      color: #fff;
      border: none;
      border-radius: 5px;
    }
  }

  .passenger-form-page-preform__resetAndNext {
    justify-content: center;
  }
}

@include min-tablets {
  .passenger-form-page-preform__luxury-component {
    h3 {
      font-size: 20px;
    }

    .luxury-component__seats {
      font-size: 16px;
    }

    .luxury-component__button {
      width: 100%;
      font-size: 14px;
    }

    .luxury-component__passenger-info {
      width: 100%;

      p {
        font-size: 14px;
      }

      .luxury-component__button-small {
        font-size: 14px;
      }
    }
  }
}

@include phones {
  .passenger-form-page-preform__luxury-component {
    h3 {
      font-size: 16px;
    }

    .luxury-component__seats {
      font-size: 12px;
    }

    .luxury-component__button {
      font-size: 10px;
    }

    .luxury-component__passenger-info {
      p {
        font-size: 10px;
      }

      .luxury-component__number-selected {
        font-size: 14px;
      }

      .luxury-component__button-small {
        font-size: 10px;
      }
    }
  }
}

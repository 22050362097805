@import '../Media.scss';

.train-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 60vh;
  margin-top: 120px;

  .train-list__items {
    min-height: 67vh;
    padding: 40px 0;
    background: linear-gradient(180deg, #d1dbff 10%, #ffffff 120%);

    .train-list__change-direction {
      margin-bottom: 97px;
      padding: 0 20px;
      background-color: #ffffff;

      h2 {
        max-width: 1283px;
        margin: 0 auto;
      }

      .ticket-form {
        .ticket-form__scrolldown {
          display: none;
        }

        .ticket-form__elements-payment {
          display: none;
        }

        label {
          display: none;
        }

        .ticket-form__elements {
          padding: 12px 0;

          input {
            &::placeholder {
              color: #666666;
            }
          }

          .ticket-form__default-cities {
            display: none;
          }

          .ticket-form__elements-city-selection {
            max-width: 530px;
            width: 37%;

            .ticket-form__elements-city-selection-from,
            .ticket-form__elements-city-selection-to {
              max-width: 240px;
              width: 45%;

              input {
                border-radius: 15px;
              }
            }
          }

          .ticket-form__elements-date-selection {
            max-width: 530px;
            width: 37%;

            .ticket-form__elements-date-selection-departure-date,
            .ticket-form__elements-date-selection-date-return {
              max-width: 240px;
              width: 45%;
            }

            .ticket-form__elements-date-selection-date-return {
              position: relative;

              .ticket-form__elements-date-selection-date-return-label {
                position: absolute;
                top: 15px;
                right: 15px;

                input {
                  width: 16px;
                  border-radius: 0;
                }
              }
            }

            input {
              width: 100%;
              border-radius: 15px;
            }
          }

          .ticket-form__elements-submit-btn {
            max-width: 262px;
            max-height: 64px;

            button {
              width: 100%;
              height: 100%;
              margin-top: 0;
              box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
              border-radius: 10px;
            }
          }
        }

        .ticket-form__return-page {
          .ticket-form__elements-date-selection {
            position: relative;
            max-width: 100%;
            width: 28%;

            .ticket-form__elements-date-selection-departure-date {
              max-width: 100%;
              width: 100%;
            }

            .ticket-form__elements-date-selection-date-return {
              display: none;
            }

            .ticket-form__elements-date-selection-date-img {
              position: absolute;
              top: 13px;
              right: 25px;
            }

            input {
              width: 100%;
              border-radius: 15px;
            }
          }
        }
      }
    }

    .train-list__trains {
      padding: 0 10px;

      .train-list__trains-main-info {
        max-width: 1283px;
        margin: 0 auto;

        h1 {
          font-size: 35px;
          font-weight: 700;
          font-family: 'Calibri';
        }

        span {
          font-size: 22px;
          font-weight: 400;
        }
      }

      .train-list__train {
        margin-bottom: 52px;
      }
    }
  }
}

@include tablets {
  .train-list {
    .train-list__items {
      .train-list__change-direction {
        display: none;
      }

      .train-list__trains {
        .train-list__trains-main-info {
          h1 {
            font-size: 24px;
          }
        }
      }
    }
  }
}

@include phones {
  .train-list {
    margin-top: 80px;

    .train-list__items {
      padding: 0;
      padding-top: 24px;

      .train-list__trains {
        .train-list__trains-main-info {
          h1 {
            font-size: 17px;
          }

          span {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@import "../Media.scss";

.error {
  font-size: 13px;
  height: 15px;
  color: red;
}
.passenger-form {
  margin-bottom: 40px;
  padding: 16px 48px;
  background: #ffffff;
  box-shadow: -1px 4px 12px rgba(56, 94, 131, 0.28);
  border-radius: 15px;

  .form-control {
    border-radius: 15px;
    background: #ffffff;

    &:focus {
      border: 2px solid #90a9ff;
      box-shadow: none;
    }
  }

  .hide {
    display: none;
  }

  .searchInput {
    border: 1px solid #d9d9d9;
    border-radius: 15px;
  }

  input[type="text"] {
    width: 100%;
    height: 77px;
    background: #ffffff;
  }

  select {
    width: 100%;
    height: 39px;
  }

  .form-select {
    border-radius: 15px;

    &:focus {
      border: 2px solid #90a9ff;
      box-shadow: none;
    }
  }

  .ticket-form__elements-date-selection-date-return-label {
    display: flex;
    align-items: center;

    div {
      margin-top: 0;
      z-index: 10;
    }

    label {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }

  .passenger-form__select-document {
    height: 77px;
  }

  .passenger-form__complete-name {
    margin-top: 24px;

    input[type="checkbox"] {
      height: 16px;
    }

    input:disabled {
      background-color: #d5d5d5;
    }
  }

  .gender-radio {
    display: flex;
    justify-content: space-between;
    width: 70%;

    .form-check {
      display: flex;
      align-items: center;

      &:nth-child(1) {
        label {
          margin-right: 10px;
        }
      }
    }

    input {
      margin-right: 5px;
      background: #ffffff;

      &:checked {
        background-color: #0d6efd;
      }
    }
  }

  .passenger-form__documents {
    .col {
      max-width: 407px;
    }

    .passenger-form__select-document {
      option {
        overflow: scroll;
        max-width: 50px;
        min-width: 50px;
        width: 50px !important;
      }
    }
  }

  .passenger-form__communications {
    .col {
      max-width: 407px;
    }
  }

  .passenger-form__babys-info {
    .passenger-form__babys-fullname {
      padding: 0;
      margin: 0;
    }

    input[type="checkbox"] {
      height: 16px;
    }

    input:disabled {
      background-color: #d5d5d5;
    }

    .passenger-form__babys-documents {
      padding: 0;
      margin: 0;
    }
  }
}

@include desktop {
  .passenger-form {
    padding: 8px 24px;
  }
}

@include tablets {
  .passenger-form {
    padding: 4px 12px;

    .passenger-form__select-document {
      height: 47px;
      padding-top: 1px;
    }

    .passenger-form__complete-name {
      margin-top: 16px;

      input {
        height: 47px;
      }

      input[type="checkbox"] {
        height: 16px;
      }

      input:disabled {
        background-color: #d5d5d5;
      }
    }

    .passenger-form__additional-info {
      .passenger-form__passenger-category {
        min-width: 100%;

        select {
          padding-top: 1px;
        }
      }
    }

    .passenger-form__documents {
      flex-direction: row;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      .hide {
        display: none;
      }

      input {
        height: 47px;
      }
    }

    .passenger-form__communications {
      input,
      select {
        height: 47px;
      }
    }

    .passenger-form__babys-info {
      input {
        height: 47px;
      }

      .gender-radio {
        input {
          height: 16px;
        }
      }

      .passenger-form__babys-documents {
        margin-bottom: 10px;

        .col {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          &:nth-child(3) {
            min-width: 215px;
            width: 100%;
          }
        }
      }
    }
  }
}

@include min-tablets {
  .passenger-form {
    .passenger-form__documents {
      flex-direction: column;

      .col {
        max-width: 100%;
      }
    }

    .passenger-form__babys-info {
      .passenger-form__babys-fullname {
        flex-direction: column;
      }
      input[type="checkbox"] {
        height: 16px;
      }

      input:disabled {
        background-color: #d5d5d5;
      }
      .passenger-form__babys-documents {
        flex-direction: column;
      }
    }
  }
}

@include phones {
  .passenger-form {
    padding: 12px 16px 25px;
    border-radius: 5px;

    span {
      font-size: 13px;
    }

    label {
      display: block;
      font-size: 15px;
    }

    .passenger-form__complete-name {
      margin-top: 0;
      flex-direction: column;
    }

    input[type="checkbox"] {
      height: 16px;
    }

    input:disabled {
      background-color: #d5d5d5;
    }

    .passenger-form__additional-info {
      flex-direction: column;
    }

    .passenger-form__documents {
      flex-direction: column;
    }

    .passenger-form__communications {
      .passenger-form__email {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      .passenger-form__rate {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 31px;
      }
    }
  }
}

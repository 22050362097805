.railwayDate {
  input {
    width: 100%;
    height: 50.21px;
    padding: 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background: linear-gradient(91.1deg, #FFE5BE 2.62%, #FFF4EA 99.65%);
    border-radius: 5px;

    &::placeholder {
      font-size: 16px;
      font-weight: 500;
      color: transparent;

      @media screen and (max-width: 480px) {
        color: #000000;
      }
    }

    &:focus {
      outline: none;
    }

    @media screen and (max-width: 480px) {
      font-size: 16px;
      font-weight: 500;
      height: 56px;
    }
  }
}

.rmdp-container {
  width: 100%;
}
@import "../../Media.scss";

.carousel {
    display: flex;
    justify-content: space-between;
    max-width: 1355px;
    margin: 0 auto;

    .slick-list {
        bottom: 180px;
        display: flex;
        align-items: center;
        width: 1272px;
        height: 435px;
        margin: 0 auto;

        .slick-track {
            display: flex;

            .slick-slide {
                width: 439px;
                padding: 0 23px;
                transition: transform 0.3s ease-in-out;
                transform: scaleX(1) scaleY(1);
            }

            .slick-center {
                transform: scaleX(1.1) scaleY(1.15);
            }
        }
    }

    .carousel__item {
        .carousel__item-body {
            display: flex;
            flex-direction: column;
            text-decoration: none;

            .item-img {
                img {
                    width: 100%;
                    border-radius: 5px 5px 0 0;
                }
            }

            .carousel__item-description {
                width: 100%;
                padding: 17px;
                font-size: 20px;
                font-weight: 600;
                line-height: 25px;
                color: #000000;
                background-color: #FFFFFF;
                border-radius: 0 0 5px 5px;
            }
        }

        .carousel__item-body-reverse {
            flex-direction: column-reverse;

            .carousel__item-description {
                border-radius: 5px 5px 0 0;
            }

            .item-img {
                img {
                    border-radius: 0 0 5px 5px;
                }
            }
        }
    }

    .carousel__arrow {
        position: relative;
        top: 35px;
        cursor: pointer;
    }
}

@media screen and (max-width: 1330px) {
    .carousel {
        justify-content: space-around;

        .slick-list {
            max-width: 1140px;

            .slick-track {
                .slick-slide {
                    width: 380px;
                    padding: 0 23px;
                }
            }
        }
    }
}

@include desktop {
    .carousel {
        .slick-list {
            width: 880px;

            .slick-track {
                .slick-slide {
                    width: 285px;
                    padding: 0 15px;
                }
            }
        }

        .carousel__item {
            .carousel__item-body {
                .carousel__item-description {
                    padding: 10px;
                    line-height: 16px;
                    font-size: 13px;
                }
            }
        }
    }
}

@include tablets {
    .carousel {
        .carousel__arrow {
            display: none;
        }

        .slick-list {
            bottom: 105px;
            width: 521px;
            height: 300px;

            .slick-track {
                .slick-slide {
                    width: 245px;
                    height: 269px;
                    padding: 0 18px;
                }

                .slick-center {
                    transform: scaleX(1) scaleY(1);
                }
            }
        }

        .carousel__item {
            width: 219px;
            height: 269px;

            .carousel__item-body {
                justify-content: flex-start;
                width: 219px;
                height: 269px;

                .carousel__item-description {
                    z-index: 1;
                    height: 69px;
                    padding: 4px;
                    padding-top: 10px;
                    font-size: 12px;
                    line-height: 16px;
                    border-radius: 10px;
                }

                .item-img {
                    width: 219px;
                    height: 200px;
                    margin-bottom: -15px;

                    img {
                        height: 100%;
                        border-radius: 15px 15px 0 0;

                    }
                }
            }

            .carousel__item-body-reverse {
                justify-content: flex-end;

                .carousel__item-description {
                    margin-bottom: -15px;
                }

                .item-img {
                    img {
                        border-radius: 0 0 15px 15px;
                    }
                }
            }
        }
    }
}

@include min-tablets {
    .carousel {
        .slick-list {
            width: 481px;
        }
    }
}


@include phones {
    .carousel {
        width: 100%;

        .slick-slider {
            width: 100%;
        }

        .slick-list {
            bottom: 15px;
            max-width: 390px;
            width: 100%;
        }
    }
}